/*!
 * Swissleader Main
 * Main Javascript File for Swiss Leadership Forum Website
 * http://www.manu.ch
 * by Manuel Isenschmid
 */

 (function() {
     'use strict';

     //---------------------------------------------------
     //  Init
     //---------------------------------------------------

     function init() {
         handleEvents();
         handleButtons();
         handlePageSpecific();

         lazyLoad();
     }
     //window.onload=init();

    //---------------------------------------------------
    //  Globals
    //---------------------------------------------------

    //var XX = null;

    //---------------------------------------------------
    //  Dom Elements
    //---------------------------------------------------

    var h = document.documentElement,
    		b = document.body,
        header = document.getElementById('header'),
        navcontent = document.getElementById('nav-content');

    //---------------------------------------------------
    //  CSS Classes
    //---------------------------------------------------

    var CSS_HIDDEN = 'hidden',
        CSS_HAS_OVERLAY = 'has-overlay';


    //---------------------------------------------------
    //
    //  Events
    //
    //---------------------------------------------------

    function handleButtons(){
        /*Toggle dropdown list*/
        /*https://gist.github.com/slavapas/593e8e50cf4cc16ac972afcbad4f70c8*/// Toggle the color of the header element

        document.onclick = check;
        function check(e){
            var target = (e && e.target) || (event && event.srcElement),
                navMenuDiv = navcontent,
                navMenu = document.getElementById("nav-toggle");

            //Nav Menu
            if (!checkParent(target, navMenuDiv)) {
                // click NOT on the menu
                if (checkParent(target, navMenu)) {
                    // click on the link
                    if (navMenuDiv.classList.contains(CSS_HIDDEN)) {
                        navMenuDiv.classList.remove(CSS_HIDDEN);
                        h.classList.add(CSS_HAS_OVERLAY);
                    } else {
                        navMenuDiv.classList.add(CSS_HIDDEN);
                        h.classList.remove(CSS_HAS_OVERLAY);
                    }
                } else {
                    // click both outside link and outside menu, hide menu
                    navMenuDiv.classList.add(CSS_HIDDEN);
                    h.classList.remove(CSS_HAS_OVERLAY);
                }
            }
        }
        function checkParent(t, elm) {
            while(t.parentNode) {
                if( t == elm ) { return true; }
                t = t.parentNode;
            }
            return false;
        }
    }

    function handlePageSpecific(){

    }

    function handleEvents(){
        /* Scroll */
        var didScroll,
            lastScrollTop = 0,
            delta = 5;

        setInterval(function() {
            if (didScroll) hasScrolled();
        }, 100);

        function hasScrolled() {
            toggleNav();
            toggleHeader();
            toggleProgressBar();
            didScroll = false;
        }
        hasScrolled();

        window.addEventListener('scroll', function(e){
            didScroll = true;
        });
    }

    // Toggle Header on Scroll
    function toggleHeader(headerHeight){
        var st = window.scrollY,
            documentHeight = h.scrollHeight,
            windowHeight = window.innerHeight,
            headerHeight = header.offsetHeight;

        // If they scrolled down and are past the navbar, add class .top-bar-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > headerHeight){
            // Scroll Down
            header.classList.remove('header-down');
            header.classList.add('header-up');
        } else {
            // Scroll Up
            if(st + windowHeight < documentHeight) {
                header.classList.remove('header-up');
                header.classList.add('header-down');
            }
        }
        return st;
    }

    // Toggle the color of the header element
    function toggleNav(){
        var scrollpos = window.scrollY,
            navaction = document.getElementById("navAction"),
            brandname = document.getElementById("brandname"),
            toToggle = document.querySelectorAll(".toggleColour");

        /*Apply classes for slide in bar*/
        scrollpos = window.scrollY;

        if(scrollpos > 10){
            header.classList.add("bg-white");
            header.classList.remove("text-white");
            header.classList.add("text-black");
            //Use to switch toggleColour colours
            for (var i = 0; i < toToggle.length; i++) {
                toToggle[i].classList.add("text-black");
                toToggle[i].classList.remove("text-white");
            }
            header.classList.add("shadow");
        } else {
            header.classList.remove("bg-white");
            header.classList.remove("text-black");
            header.classList.add("text-white");

            //Use to switch toggleColour colours
            for (var i = 0; i < toToggle.length; i++) {
                toToggle[i].classList.add("text-white");
                toToggle[i].classList.remove("text-gray-800");
            }

            header.classList.remove("shadow");
        }
    }

    /* Progress bar */
    function toggleProgressBar(){
        //Source: https://alligator.io/js/progress-bar-javascript-css-variables/
        var progress = document.querySelector('#progress'),
            st = 'scrollTop',
        		sh = 'scrollHeight',
            scrollpos = window.scrollY,
        		scroll;

        if (progress){
        		/*Refresh scroll % width*/
        		scroll = (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
        		progress.style.setProperty('--scroll', scroll + '%');

        		/*Apply classes for slide in bar*/
        		scrollpos = window.scrollY;

        		if (scrollpos > 10) {
        				header.classList.add("bg-white");
        				header.classList.add("shadow");
        		} else {
        				header.classList.remove("bg-white");
        				header.classList.remove("shadow");
        		}
        }
    }


    //---------------------------------------------------
    //
    //  Helpers
    //
    //---------------------------------------------------


    //---------------------------------------------------
    //
    //  Functions
    //
    //---------------------------------------------------

    /* Lazy Loading by Craig Buckler, @craigbuckler
       edited by Manuel Isenschmid, Talfrisch GmbH */
    function lazyLoad(){
        if (window.addEventListener && window.requestAnimationFrame && document.getElementsByClassName) window.addEventListener('load', function() {
            // start
            var pItem = document.getElementsByClassName('progressive replace'), pCount, timer, viewIndependent;
            // scroll and resize events
            window.addEventListener('scroll', scroller, false);
            window.addEventListener('resize', scroller, false);
            // DOM mutation observer
            if (MutationObserver) {
                var observer = new MutationObserver(function() {
                    if (pItem.length !== pCount) inView();
                });
                observer.observe(document.body, { subtree: true, childList: true, attributes: true, characterData: true });
            }
            // initial check
            inView();
            // throttled scroll/resize
            function scroller() {
                timer = timer || setTimeout(function() {
                    timer = null;
                    inView();
                }, 300);
            }
            // image in view?
            function inView() {
              if (pItem.length) requestAnimationFrame(function() {
                  var wT = window.pageYOffset, wB = wT + window.innerHeight, cRect, pT, pB, p = 0;
                  while (p < pItem.length) {
                      cRect = pItem[p].getBoundingClientRect();
                      pT = wT + cRect.top;
                      pB = pT + cRect.height;

                      if(pItem[p].classList.contains('view-independent')) viewIndependent=true;
                      if (wT < pB && wB > pT || viewIndependent) {
                          loadFullImage(pItem[p]);
                          pItem[p].classList.remove('replace');
                      }
                      else p++;
                  }
                  pCount = pItem.length;
              });
            }
            // replace with full image
            function loadFullImage(item) {
                var href = item && (item.getAttribute('data-href') || item.href);
                if (!href) return;
                // load image
                var img = new Image();
                if (item.dataset) {
                    img.srcset = item.dataset.srcset || '';
                    img.sizes = item.dataset.sizes || '';
                }
                img.src = href;
                img.className = 'reveal';
                if (img.complete) addImg();
                else img.onload = addImg;
                // replace image
                function addImg() {
                    requestAnimationFrame(function() {
                        // disable click
                        if (href === item.href) {
                            item.style.cursor = 'default';
                            item.addEventListener('click', function(e) { e.preventDefault(); }, false);
                        }
                        // add full image
                        item.appendChild(img).addEventListener('animationend', function(e) {
                            // remove preview image
                            var pImg = item.querySelector && item.querySelector('img.preview');
                            if (pImg) {
                                e.target.alt = pImg.alt || '';
                                item.removeChild(pImg);
                                e.target.classList.remove('reveal');

                                item.classList.add('loaded');
                                var parent = item.closest('.entry');
                                if (parent != null) parent.classList.add('loaded');
                            }
                        });
                    }); // end requestAnimationFrame
                }
            }
        }, false);
    }

    init(); // self-invoking
}());
